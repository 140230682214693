<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <div class="text-center" style="color: #fff; letter-spacing: 2px">
          Hello!<br />如資料有變更請洽系統管理員
        </div>
      </div>
    </div>

    <div class="page-body">
      <form class="information-form">
        <div class="information-field">
          <label> 公司名稱 </label>
          <span>{{ information.cusT_SNM }}</span>
        </div>

        <div class="information-field">
          <label> 可送貨日 </label>
          <span>{{ information.weeK_DAYS }}</span>
        </div>

        <div class="information-field">
          <label> 電子信箱 </label>
          <span>{{ information.emlX_ADR }}</span>
        </div>

        <div class="information-field">
          <label> 是否可下假日單 </label>
          <span v-if="information.restdaY_SEND === 'Y'">是</span>
          <span v-else>否</span>
        </div>

        <div v-if="information.arxX_DDX" class="information-field">
          <label> 結帳日 </label>
          <span>{{ information.arxX_DDX }}</span>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-warning mr-1"
            data-toggle="modal"
            data-target="#changePasswordModal"
            @click="(changeData = {}), $refs.changePasswordValidate.reset()"
          >
            修改密碼
          </button>
        </div>
      </form>
    </div>

    <!-- 變更密碼 Modal -->
    <div
      class="modal fade"
      id="changePasswordModal"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <ValidationObserver ref="changePasswordValidate" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(changePassword)">
              <div class="modal-header">
                <h5>修改密碼</h5>
              </div>
              <div class="modal-body">
                <div class="field">
                  <ValidationProvider vid="confirmation" rules="required" v-slot="{ errors }">
                    <label>
                      <span class="asterisk">*</span>新密碼
                      <input
                        type="password"
                        placeholder="請輸入新密碼"
                        v-model="changeData.newPassword"
                      />
                      <span class="text-red">{{ errors[0] }}</span>
                    </label>
                  </ValidationProvider>
                </div>
                <div class="field">
                  <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors }">
                    <label>
                      <span class="asterisk">*</span>確認密碼
                      <input
                        type="password"
                        placeholder="請再次輸入新密碼"
                        v-model="changeData.confirmPassword"
                      />
                      <span class="text-red">{{ errors[0] }}</span>
                    </label>
                  </ValidationProvider>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  取消
                </button>
                <button type="submit" class="btn btn-primary">
                  儲存
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import { mapActions } from "vuex";
import { apiChangePassword } from "@/apis/login";

export default {
  data() {
    return {
      // 個人資訊
      information: {},

      // 修改密碼
      changeData: {
        newPassword: "",
        confirmPassword: ""
      },

      // 星期
      weekday: ["日", "一", "二", "三", "四", "五", "六"]
    };
  },
  methods: {
    // 變更密碼
    changePassword() {
      $("#changePasswordModal").modal("hide");
      this.updateLoading(true);
      apiChangePassword({
        account: localStorage.ACCOUNT,
        newpassword: this.changeData.newPassword
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.$notify({
              title: "成功",
              message: "已變更密碼",
              type: "success",
              duration: 2500
            });
          }
        });
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  created() {
    this.information = JSON.parse(localStorage.CUSTOMER_INFO);
    this.information.weeK_DAYS = this.weekday
      .filter((item, index) => [...this.information.weeK_DAYS.split(",")][index] === "1")
      .toString();
  }
};
</script>

<style lang="scss" scoped>
.information-form {
  padding: 40px;
  max-width: 960px;
  margin: 0 auto;

  .information-field {
    position: relative;
    label {
      font-weight: 600;
      display: inline-block;
      background-color: #ffdeb8;
      padding: 3px 10px;
      border-radius: 3px;
      margin-bottom: 0;
      margin-left: 5px;
      width: 120px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      text-align: center;
    }

    span {
      font-weight: 600;
      cursor: default;
      background-color: #fff;
      display: block;
      width: 100%;
      padding: 0 15px;
      border: none;
      line-height: 35px;
      height: 35px;
      border-radius: 5px;
      margin-bottom: 20px;
      padding-left: 140px;
      outline: none;
    }
  }
}
</style>
